import React, { useEffect, useReducer } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CampaignList from "../components/CampaignList"
import InfoSection from "../components/InfoSection"
import SEO from "../components/seo"
import { sanitize, byPriority } from "../utils"
import { initialSelectionState } from "../utils/initialState"
import selectionReducer from "../utils/selectionReducer"

const IndexPage = ({ data }) => {
  const [_, selectionDispatch] = useReducer(
    selectionReducer,
    initialSelectionState
  )

  useEffect(() => {
    selectionDispatch({ type: "RESET_ALL" })
  }, [])

  const campaigns = byPriority(sanitize("allContentfulCampaign", data))
  const airmailImg = data.file.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Congress.Cards" />
      <div id="home">
        <section className="top">
          <div className="left">
            <h1>
              Welcome to Congress.Cards, where you can customize and mail real
              postcards to your representatives about the causes you care about.
            </h1>
            <h2>
              It costs just $2 to mail a postcard. For every card sent, we
              donate $1 to your cause.
            </h2>
          </div>
          <div className="right">
            <Img fluid={airmailImg} alt="airmail image" />
          </div>
        </section>
        <div className="grey-bg-section border-top">
          <CampaignList campaigns={campaigns} />
          <InfoSection />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulCampaign(filter: { active: { eq: true } }) {
      edges {
        node {
          ...Basic
        }
      }
    }
    file(relativePath: { glob: "airmail.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
