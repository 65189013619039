import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faScroll,
  faFeatherAlt,
  faPaperPlane,
  faPalette,
} from "@fortawesome/free-solid-svg-icons"

export default function InfoSection() {
  return (
    <section id="info-section">
      <div className="container">
        <h3>How it works</h3>
        <div className="row ">
          <div className="">
            <div className="circle-wrapper">
              <div className="circle">
                <FontAwesomeIcon icon={faScroll} color="white" size="3x" />
              </div>
            </div>
            <h4>Choose Your Cause</h4>
            <p className="mono">
              For every card sent, $1 will be donate to an organization
              supporting that cause.
            </p>
          </div>
          <div className="">
            <div className="circle-wrapper">
              <div className="circle">
                <FontAwesomeIcon icon={faPalette} color="white" size="3x" />
              </div>
            </div>
            <h4>Choose Your Design</h4>
            <p className="mono">
              For most campaigns we offer multiple postcard designs
            </p>
          </div>
          <div className="">
            <div className="circle-wrapper">
              <div className="circle">
                <FontAwesomeIcon icon={faFeatherAlt} color="white" size="3x" />
              </div>
            </div>
            <h4>Type Your Message</h4>
            <p className="mono">
              Let your representative know why this matters to you
            </p>
          </div>
          <div className="">
            <div className="circle-wrapper">
              <div className="circle">
                <FontAwesomeIcon icon={faPaperPlane} color="white" size="3x" />
              </div>
            </div>
            <h4>Hit Send</h4>
            <p className="mono">
              We print, stamp and mail the postcards for you
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
